.alert-form {
  margin: 0;
  border-radius: 0;
  text-align: center;

  a {
    border-bottom: 2px solid;
    cursor: pointer;

    &:hover {
      font-weight: bolder;
    }
  }
}