.sidebar {
  padding: 15px;
  box-shadow: 4px 0 6px $black-light;
  text-align: left;
  font-size: 1.2em;
  height: 100%;
  background-color: darken($bg-color, 2);

  &-header {
    font-size: 1.7em;
    color: $secondary;

    &:hover {
      color: $primary;
    }
  }

  &-text {
    color: $secondary;
    padding: 5px;
  }

  &-action {
    cursor: pointer;
    color: $secondary;
    padding: 5px;

    &:hover {
      color: $primary;
    }
  }

  &-items {
    padding-top: 50px;
    padding-bottom: 100px;

    &-item {
      padding: 5px;
      width: 100%;
      color: $secondary;
      display: inline-block;
    }

    &-item:hover {
      cursor: pointer;
      color: $primary;
    }

    &-active {
      border-right: 8px solid $primary;
    }

  }
}