.l-form {
  min-height: 100vh; // height of the browser viewport
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .alert {
    margin-bottom: 20px;
    border-radius: 10px;
  }

}

.l-main {
  display: grid;
  grid-template-columns: 300px 1fr;
  height: 100%;

  .content {
    padding: 50px;
  }
}